import React from "react"

import Category from "../layouts/category"
import { convert2Articles, sortArticles } from "../utils/helper"
import Layout from "../layouts"

export default ({ data }) => {
  const articles = convert2Articles(data.allMarkdownRemark.nodes)
  const sortedArticles = sortArticles(articles)

  return (
    <Layout isSpacerDisplay={false} title="相关文章">
      <Category articles={sortedArticles} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogColumnQuery {
    allMarkdownRemark {
      nodes {
        excerpt(truncate: true, pruneLength: 40)
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          tag
        }
        fields {
          slug
        }
      }
    }
  }
`
