import React from "react"

import styles from "./category.module.css"
import { DEVELOPER_TAG, USER_TAG, INTRO_TAG } from "../utils/constant"

export default ({ articles }) => {
  const userDocs = articles.filter(item => item.tag === USER_TAG)
  const introArticles = articles.filter(item => item.tag === INTRO_TAG)
  const developerDocs = articles.filter(item => item.tag === DEVELOPER_TAG)

  return (
    <div className={styles.outerContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <p className={styles.tag}>{USER_TAG}</p>

          <ul className={styles.ul}>
            {userDocs.map((article, index) => (
              <li className={styles.li} key={index}>
                <span className={styles.item}>
                  <span className={styles.title}>
                    <a
                      rel="noopener noreferrer"
                      href={article.slug}
                      className={styles.slug}
                    >
                      {article.title}
                    </a>
                  </span>
                  <span className={styles.row}>
                    <span className={styles.date}>{article.date}</span>
                  </span>
                </span>
              </li>
            ))}
          </ul>

          <p className={styles.tag}>{INTRO_TAG}</p>
          
          <ul className={styles.ul}>
            {introArticles.map((article, index) => (
              <li className={styles.li} key={index}>
                <span className={styles.item}>
                  <span className={styles.title}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={article.slug}
                      className={styles.slug}
                    >
                      {article.title}
                    </a>
                  </span>
                  <span className={styles.row}>
                    <span className={styles.date}>{article.date}</span>
                  </span>
                </span>
              </li>
            ))}
          </ul>

          <p className={styles.tag}>{DEVELOPER_TAG}</p>
          
          <ul className={styles.ul}>
            {developerDocs.map((article, index) => (
              <li className={styles.li} key={index}>
                <span className={styles.item}>
                  <span className={styles.title}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={article.slug}
                      className={styles.slug}
                    >
                      {article.title}
                    </a>
                  </span>
                  <span className={styles.row}>
                    <span className={styles.date}>{article.date}</span>
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
